import React, { useState, MouseEvent, useEffect } from "react";
import { SVGMotionProps, motion } from "framer-motion";
import styles from './styles.module.scss';

const Path = (props: React.JSX.IntrinsicAttributes & SVGMotionProps<SVGPathElement> & React.RefAttributes<SVGPathElement>) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="rgb(255, 255, 255)"
    strokeLinecap="round"
    {...props}
  />
);

interface Props {
  active?: boolean;
  toggle?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const MenuToggle: React.FC<Props> = ({ 
  active,
  toggle 
}) => (
  <button className={styles.button} onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        d="M 2 2.5 L 20 2.5"
        animate={active ? "open" : "closed"}
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" }
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        animate={active ? "open" : "closed"}
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 }
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        d="M 2 16.346 L 20 16.346"
        animate={active ? "open" : "closed"}
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" }
        }}
      />
    </svg>
  </button>
);

const MobileNav = ({ items, toggle = false }: { items: any[], toggle?: boolean}) =>{
  const [isOpen, setIsOpen] = useState(false)
  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 32px 27px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(20px at 32px 27px)",
      transition: {      
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

  useEffect(() => {
    if(toggle){
      setIsOpen(!isOpen);
    }
  },[toggle])

  const NavOptions = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  }

  const NavUlOptions = {
    open: {transition: { staggerChildren: 0.07, delayChildren: 0.2 }},
    closed: {transition: { staggerChildren: 0.05, staggerDirection: -1 }}
  }

  const NavLiOptions = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  }

  return ( 
    <div
      className={styles.navWrapper}    
    >
      <MenuToggle toggle={() => setIsOpen(!isOpen)} active={isOpen} />
      <motion.div 
        className={styles.navBackground}  
        animate={isOpen ? "open" : "closed"} 
        variants={sidebar} 
      >    
        <motion.nav
          animate={isOpen ? "open" : "closed"}
          variants={NavOptions}
        >
          <motion.ul variants={NavUlOptions}>
            {items.map((item: any, index: number) => <motion.li key={index} variants={NavLiOptions}>{item}</motion.li>)}
            <motion.li variants={NavLiOptions}><span className={styles.emblem}>J&D</span></motion.li>
          </motion.ul>        
        </motion.nav >
      </motion.div>
    </div> 
  )
}
export default MobileNav;