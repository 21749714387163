import React, { forwardRef, useEffect, useRef, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion"

import "./global.module.scss";

// errors
import NotFoundPage from "./containers/Errors/404";

// layouts
import BaseLayout  from "./layouts/BaseLayout";

// misc
import RenderCompleted  from "./hooks/RenderCompleted";

// containers
import Home from "./containers/Home";
import Main from "./containers/Main";
import useAuthService from "hooks/AuthService";


const PrivateRoutes = () => {
  const { authenticate } = useAuthService();
  const loadingRef = useRef(true);
  const authenticatedRef = useRef(false);
  const [initialized, setInitialized] = useState(false);

  const checkAuthentication = async () => {
    authenticatedRef.current = await authenticate();
    loadingRef.current = false;
    setInitialized(true);      
  };


  useEffect(() => {
    if (!initialized) {
      checkAuthentication();
    }
  }, [initialized]);

  if (!initialized || loadingRef.current) return null;
  return authenticatedRef.current ? <Outlet /> : <Navigate to="/" />;
}

const RoutesWithAnimation = forwardRef(() => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>    
        <Route path="/" element={<Home />} />
        
        <Route element={<PrivateRoutes/>}>
          <Route path="/wedding" element={<Main />} />
        </Route>

        {/* 404 page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>      
    </AnimatePresence>
  )  
});


function App() {
  const isMounted = RenderCompleted();

  return (
    <>
      {isMounted && (
        <BrowserRouter>
          <BaseLayout>
            <Routes>
              <Route path="*" element={<RoutesWithAnimation />} />
            </Routes>
          </BaseLayout>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
