// useAuthService.ts

import { useContext } from "react";
import { decryptData, encryptData } from "./Encryptions";
import fetchApi from "./fetchApi";
import AuthContext from "context/Auth";

const useAuthService = () => {
  const { user, setUser } = useContext(AuthContext);
  const login = async (password: string): Promise<{[key: string]: any}> => {
    try {
      const res = await fetchApi(`/auth/password`, {}, {
        method: "POST",
        body: JSON.stringify({ password })
      });
      if (!!res?.token) {
        setUser(res);
        const encryptedData = await encryptData(res || {}, process.env.REACT_APP_ENCRYPT_KEY || "");
        localStorage.setItem('authorized', encryptedData);        
        return { success : true};
      }
      return { success : false, error : { "message" : res?.message || "Bad credentials"}};      
    } catch (error) {
      return { success : false, error : { "message" : error?.message || "Bad credentials"}};
    }
  };

  const logout = (): void => {
    setUser(null);
    localStorage.removeItem('authorized');
  };

  const authenticate = async (): Promise<boolean> => {
    if (user?.token) {
      const res = await fetchApi(`/auth/password-verify`, {}, {},{ "Authorization" : `Bearer ${user.token}`});
      if(!res?.success) {
        logout();
        return false;
      }
      return true;
    }

    const authorized = localStorage.getItem('authorized');
    const data = await decryptData(authorized || "", process.env.REACT_APP_ENCRYPT_KEY || "");
    if (!!data?.token) {
      if (data?.expires < Date.now()) {
        logout();
        return false;
      } else {
        const res = await fetchApi(`/auth/password-verify`, {}, {}, { "Authorization" : `Bearer ${data.token}`});
        if(!res?.success) {
          logout();
          return false;
        }
        setUser(data);
        return true;
      }
    }

    logout();
    return false;

  }

  return { authenticate, login, logout };
};

export default useAuthService;
