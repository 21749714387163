import CryptoJS from 'crypto-js';


export const encryptData = async (data: any, key: string) => {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return ciphertext;
}

export const decryptData = async (ciphertext: string, key: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const decryptedData = await JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch(error) {
    return {}
  }
}
