import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import Button from "components/Button";

export default function ToggleInput({ 
  label,
  description,
  labelTrue,
  labelFalse,
  required,
  checked, 
  className,
  error,
  onChange 
}: {
  label?: string | React.ReactNode;
  description?: string;
  labelTrue?: string;
  labelFalse?: string;  
  required?: boolean;
  checked?: boolean;
  className?: string;
  error?: any;
  onChange?: (e?: any) => void;
}) {

  const [newChecked,setNewChecked] = useState<boolean>(checked || false);

  const setSelectValue = function (e: any){            
    if(typeof onChange === "function"){
      onChange(!newChecked)
    }
  }

  useEffect(() => {
    setNewChecked(checked || false)
  },[checked]);

  return (
    <div className={[styles.inputWrap, className, error?'error-input':''].filter(Boolean).join(' ')}>
      {label && 
        <div className={styles.labelWrap}>
          <label>{label}</label>
          {description && <span><small>{description}</small></span>} 
        </div>
      }     
      <Button scheme="plain" type="button" className={styles.toggleWrapper} onClick={setSelectValue}>      
        <div className={[styles.labelTrue,newChecked ? styles.active : ''].filter(Boolean).join(' ')}>{labelTrue || "ON"}</div>      
        <div className={[styles.labelFalse,newChecked ? '' : styles.active].filter(Boolean).join(' ')}>{labelFalse || "OFF"}</div>      
      </Button>   
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}