import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';
import Eye from "components/svg/Eye";
import EyeHidden from "components/svg/EyeHidden";


const PasswordInput = ({ 
  name, 
  label, 
  value,
  description, 
  className,
  type, 
  required,
  disabled, 
  readonly, 
  autocomplete, 
  placeholder, 
  error,   
  onChange,
} : any) => {

  const [newValue, setNewValue] = useState<any>(value);
  const [visible, setVisible] = useState(false);

  const handleChange = async function (e: any){    
    if(disabled) return true;
    setNewValue(e.target.value);    
    if(typeof onChange === 'function'){
      onChange(e.target.value)      
    } 
  }

  useEffect(() => {
    setNewValue(value);
  },[value]);

  return (
    <>
      {label && 
        <div className={styles.labelWrap}>
          <label>{label}</label>
          {description && <span><small>{description}</small></span>} 
        </div>
      }
      <div className={[styles.inputWrap, className, error?'error-input':''].filter((i)=>i).join(' ')}>
        <div className={styles.inputPassword}>
          <input                     
            type={!visible ? 'password' : 'text'}
            name={name} 
            value={newValue || ""}
            disabled={disabled} 
            readOnly={readonly} 
            placeholder={placeholder?placeholder+(required?' *':''):''} 
            autoComplete={autocomplete}  
            onChange={handleChange}           
          />   
          <div className={styles.visibleWrap} onClick={()=>setVisible(!visible)}>
            {!visible && <Eye width="25px" />}
            {visible && <EyeHidden  width="25px"/>}
          </div>
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </>
  );
};

export default PasswordInput;
