import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';


const TextInput = ({ 
  name, 
  label, 
  value,
  description, 
  className,
  type, 
  required,
  disabled, 
  readonly, 
  autocomplete, 
  placeholder, 
  error,   
  onChange
} : any) => {

  const [newValue, setNewValue] = useState<any>(value);


  const handleChange = async function (e: any){    
    if(disabled) return true;
    setNewValue(e.target.value);    
    if(typeof onChange === 'function'){
      onChange(e.target.value)      
    } 
  }

  useEffect(() => {
    setNewValue(value);
  },[value]);

  return (
    <div className={[styles.inputWrap, className, error?'error-input':''].filter((i)=>i).join(' ')}>
      {label && 
        <div className={styles.labelWrap}>
          <label>{label}</label>
          {description && <span><small>{description}</small></span>} 
        </div>
      }
      <input                     
        type={type ? type : "text"} 
        name={name} 
        value={newValue || ""}
        disabled={disabled} 
        readOnly={readonly} 
        placeholder={placeholder?placeholder+(required?' *':''):''} 
        autoComplete={autocomplete}  
        onChange={handleChange}           
      />      
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default TextInput;
