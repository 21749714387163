import React from "react";

const Arrow = (props: any) => {
  const svgStyles: any = {
    width: props.width ? props.width : null,
    height: props.height ? props.height : null,
    fill: props.fill ? props.fill : null,
    margin: props.margin ? props.margin : null,
  };

  const filteredStyles = Object.keys(svgStyles)
  .filter(key => svgStyles[key] !== null && svgStyles[key] !== undefined)
  .reduce((acc: any, key) => {
    acc[key] = svgStyles[key];
    return acc;
  }, {});

  return (
    <svg       
      width={200}
      viewBox="0 0 200 129" 
      fill="none" 
      style={{...filteredStyles, fill : 'none'}}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M129.871 3.78867C135.668 -1.56284 144.706 -1.20137 150.058 4.59603L196.211 54.5955C201.263 60.0677 201.263 68.5026 196.212 73.9749L150.058 123.975C144.706 129.773 135.668 130.134 129.871 124.783C124.073 119.432 123.712 110.394 129.063 104.596L153.086 78.571L14.2857 78.571C6.39592 78.571 -1.09165e-05 72.175 -1.124e-05 64.2853C-1.15635e-05 56.3955 6.39592 49.9995 14.2857 49.9995L153.086 49.9995L129.063 23.9756C123.712 18.1782 124.073 9.14019 129.871 3.78867Z" fill={filteredStyles.fill || '#BABEC5'}/>
    </svg>
  )
}

export default Arrow;