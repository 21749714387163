import React, { useEffect, useState } from "react";
import styles from './styles.module.scss';


const TextArea = ({ 
  name, 
  label, 
  value,
  description, 
  className,
  required,
  disabled, 
  readonly, 
  rows,
  autocomplete, 
  placeholder, 
  error,   
  onChange
} : any) => {

  const [newValue, setNewValue] = useState<any>(value);

  const handleChange = async function (e: any){    
    if(disabled) return true;
    setNewValue(e.target.value);    
    if(typeof onChange === 'function'){
      onChange(e.target.value)      
    } 
  }

  useEffect(() => {
    setNewValue(value);
  },[value]);

  return (
    <>
      {label && 
        <div className={styles.labelWrap}>
          <label>{label}</label>
          {description && <span><small>{description}</small></span>} 
        </div>
      }
      <div className={[styles.inputWrap, className, error?'error-input':''].filter((i)=>i).join(' ')}>
        <textarea                     
          rows={rows || 6}
          name={name}           
          disabled={disabled} 
          readOnly={readonly} 
          placeholder={placeholder?placeholder+(required?' *':''):''} 
          autoComplete={autocomplete}  
          onChange={handleChange}   
        >{newValue || ""}</textarea>      
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </>
  );
};

export default TextArea;
