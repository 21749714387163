import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Chateau from '../../components/svg/Chateau';
import ChateauIcon from "../../components/svg/ChateauIcon";
import Button from "../../components/Button";
import useAuthService from "../../hooks/AuthService";
import LoadingWheel from "components/LoadingWheel";
import PasswordInput from "components/Form/PasswordInput";

const Home = () => {
  const navigate = useNavigate();
  
  const { login } = useAuthService();
  const authenticatedRef = useRef(false);
  const [response, setResponse] = useState<any>(false);
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [activate, setActivate] = useState<boolean>(false);

  const _onSubmit = async (values: any) => {   
    setResponse(false);

    const res: any = await login(values.password);
    if (res?.success) {  
      setRedirecting(true);
      setTimeout(() => navigate('/wedding'), 6400);    
    } else {
      setResponse({success: false, message : res?.error?.message || "Incorrect credentials"});   
    }      
  }

  const _validate = Yup.object().shape({
    password: Yup.string().required("This field is required."),
  });

  useEffect(() => {    
    document.title = process.env.REACT_APP_NAME + " | Home"        
    setActivate(process.env.REACT_APP_LOGIN_ACTIVE === "1" ? true : false);
  },[])

  return (    
    <div className={styles.wrapper}>
      <div className="container">
        <h1 className="text-color-primary">The KuderTak Wedding</h1>
        <div className={styles.container}>
          <div className={[styles.chateauWrap].filter(i=>i).join(' ')}>
            <div className={styles.svgChange}>
              <ChateauIcon width="8%" style={{maxWidth: '150px'}} />
            </div>        
            <div className={styles.svgChange}>
              <Chateau width="100%" style={{maxWidth : '800px'}}  />
            </div>
          </div>          
        </div>
        <h3 className="ff-p22zaner text-color-secondary">May 31, 2025</h3>
        {!activate && <h3 className="text-color-primary">Coming Soon</h3>}
        {activate &&
          <div className={styles.loginBox}>
            {authenticatedRef.current && <div><Button scheme="secondary" onClick={() => { navigate('/wedding')}}>Go To Wedding</Button></div>}
            {!authenticatedRef.current &&
              <Formik initialValues={{}} onSubmit={_onSubmit} validationSchema={_validate}>
                {({ isSubmitting, errors, submitCount, setFieldValue }) => (
                  <Form noValidate autoComplete="off" className={styles.form}>                
                    {response && <div className={styles.alertWrap}><p className={response?.success===true?"text-color-success":"text-color-danger"}>{response?.message || ""}</p></div>}                                                
                    <PasswordInput                             
                      name="password" 
                      placeholder="Password"
                      onChange={(val: any) => { setFieldValue("password",val); setResponse(false) }}
                      error={errors?.password && submitCount > 0 ? errors.password : null}
                    />                                            
                    <div>
                      <Button type="submit" disabled={isSubmitting}>{isSubmitting ? <LoadingWheel width="20px" light={true} /> : "Submit"}</Button>
                    </div> 
                    {(isSubmitting || redirecting) &&
                      <div className={styles.loadingScreen}>
                        <div><p>31.2235° N, 121.4453° E<br />Shanghai</p></div>
                        <div><p>40.7705° N, 111.8944° W<br />Salt Lake City</p></div>
                        <div><p>52.0617° N, 4.4899° E<br />Zoetermeer</p></div>
                        <div><p>40.6964° N, 73.9977° W<br />Brooklyn</p></div>
                        <div><p>43.0760° N, 11.6796° E<br />Pienza</p></div>
                        <div><p>44.6933° N, 0.4209° W<br />Bordeaux<br />Chateau L&apos;Hospital</p></div>              
                      </div>
                    }
                  </Form>
                )}
              </Formik>
            }
          </div>
          }
      </div>  
    </div> 
  );            
};

export default Home;