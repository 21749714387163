import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import styles from './styles.module.scss';
import TimesIcon from "components/svg/TimesIcon";
import ChevronIcon from "components/svg/ChevronIcon";


const SelectInput = ({ 
  name, 
  label, 
  placeholder,
  value,
  description, 
  className,
  options, 
  disabled, 
  readonly, 
  autocomplete, 
  multiple,
  error,   
  onChange
} : any) => {
  
  const SelectStyles = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: "transparent",
      borderColor: "var(--color-body, #000)",
      borderRadius: "var(--rounded)",    
      paddingLeft: 10,    
      minHeight:44,      
    }),
    menu: (baseStyles: any, state: any) => ({
      ...baseStyles,
      marginTop: 0,   
      backgroundColor: "var(--color-body-secondary)", 
    }),
    option: (styles: any, state: any) => ({
      ...styles, 
      backgroundColor: state.isSelected ? "var(--color-wine)" : "", 
      color: state.isSelected ? "var(--color-body-secondary)" : "var(--color-body)", 
      "&:hover, &.react-select__option--is-focused": {
        ...styles,  
        cursor : "pointer",
        backgroundColor : state.isSelected ? "var(--color-wine)" : "var(--color-primary)",
        color: "var(--color-body-secondary)",
      },
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      padding: 10,    
    }),
  }

  const MultiValueRemove = (props: any) => {
    return (      
        <components.MultiValueRemove {...props}>
          <TimesIcon  height={"10px"} width={"10px"} fill="var(--color-body-secondary)" />          
        </components.MultiValueRemove>              
    );
  };

  const ClearIndicator = (props: any) => {
    return (      
      <components.ClearIndicator {...props}>
        <TimesIcon height={"14px"} width={"14px"} />        
      </components.ClearIndicator>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (      
      <components.DropdownIndicator {...props}>        
        <ChevronIcon height={"14px"} width={"14px"} transform={"rotate(-90deg)"} />        
      </components.DropdownIndicator>
    );
  };

  const selectRef = useRef<any>(null);
  
  const setSelectValue = function (e: any){  
    if(disabled) return true;

    let changedValue = e?.value ?? ""
    if(multiple){
      const values = e.map((el: any) => el.value).filter((v: any) => v !== "");   
      changedValue = values; 
    }

    if(typeof onChange === "function"){
      onChange(changedValue ?? "")
    }
    setTimeout(() => { selectRef?.current?.blur(); },100);
  }

  return (
    <>
      {label && 
        <div className={styles.labelWrap}>
          <label>{label}</label>
          {description && <span><small>{description}</small></span>} 
        </div>
      }
      <div className={[styles.inputWrap, className, error?'error-input':''].filter((i)=>i).join(' ')}>
        <Select 
          ref={selectRef}
          styles={SelectStyles} 
          menuPlacement="auto"
          classNamePrefix="react-select"
          options={options} 
          name={name} 
          isMulti={multiple}
          value={options?.filter((option: any) => (Array.isArray(value)) ? value.includes(option.value) : option.value === value)} 
          onChange={(e) => setSelectValue(e)}  
          components={{MultiValueRemove,ClearIndicator,DropdownIndicator}} 
          isDisabled={readonly}       
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </>
  );
};

export default SelectInput;
