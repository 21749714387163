import React, {useEffect, useState } from "react";
import styles from './styles.module.scss';

const Media = ({
  url,
  mime = "image/jpg",
  width,
  height,
  align,
  ratio,
  rounded,  
  alternativeText,
  className
} : {
  url: string;
  mime?: string;
  width?: string;
  height?: string;
  align?: "left" | "right" | "center";
  ratio?: "1x1" | "4x3" | "16x9" | "21x9" | "3x2" | "5x4" | "16x10" | "3x1" | "9x16" | "4x5" | "2x3";
  rounded?: "none" | "normal" | "large";  
  alternativeText?: string;
  className?: any
}) => {

  const [videoUrl,setVideoUrl] = useState<string | boolean>(false);
  useEffect(() => {
    setVideoUrl((mime || "").startsWith('video') ? (url || "") : false);
  },[mime,url]);

  return (
    <div 
      className={[
        styles.media,
        className,
        align ? `media-align-${align}` : '',
        ratio ? `media-ratio-${ratio.replace(":","x")}` : '',
        rounded ? `media-rounded-${rounded}` : ''
      ].filter((i)=>i).join(' ')}
      style={{width: width || undefined, height: height || undefined}} 
      >
      {videoUrl && (
        <video width="320" height="240" controls playsInline>
          <source src={(videoUrl || "").toString()} type={mime} />              
        </video>
      )}
      {!(mime || "").startsWith('video') && (
        <img src={url || ""} alt={alternativeText || ""}/>
      )}
    </div>
  )
}

export default Media;