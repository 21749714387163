import React from "react";

import styles from "./styles.module.scss";

const BaseLayout = ({children}: any) => {

  return (    
    <div className={styles.baseWrapper}>
      {children}             
    </div>
  )
};

export default BaseLayout;