import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Arrow from "../svg/Arrow";
import useWindowDimensions from 'hooks/useWindowDimensions';

export default function Carousel({ 
  items,
  columns = 2,
  className,
  toggleStyle = 'centercenter',
} : {
  items: any[];
  columns?: 1 | number;
  className?: string;
  toggleStyle?: 'centercenter' | 'bottomcenter' | 'bottomspaced' | 'topcenter' | 'topspaced';
}) {  

  const { width } = useWindowDimensions();

  const carouselRef = useRef(null);
  const scrollRef = useRef(null);
  const sliding = useRef<boolean>(false);  
  const touchStart = useRef<any>(null);
  const touchEnd = useRef<any>(null);

  const [hideToggles,setHideToggles] = useState<any>(false);
  const [slidingDir,setSlidingDir] = useState<any>(false);
  const [children, setChildren] = useState<any[]>([]);

  const onTouchStart = (e: any) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  }
  
  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return
    const distance = touchStart.current - touchEnd.current
    const isLeftSwipe = distance > 100
    const isRightSwipe = distance < -100
    if (isLeftSwipe || isRightSwipe){
      slider(isRightSwipe ? 'prev' : 'next');
    }
  }

  const onTouchMove = (e: any) => touchEnd.current = e.targetTouches[0].clientX;

  const slider = (dir: string) => {
    if (scrollRef.current || carouselRef.current) {
      const carousel: any = carouselRef.current;
      const container: any = scrollRef.current;
      let newChildren = [...children];
      
      if(carousel.offsetWidth < container.scrollWidth){
        if(!sliding.current){  
          setSlidingDir(dir);
          sliding.current = true;
          if(dir === 'prev'){                       
            const current = newChildren[newChildren.length - 1];
            const sliced = (newChildren || []).slice(0,-1);
            newChildren = [current, ...sliced];                        
            setChildren(newChildren); 
            setTimeout(() => {                    
              setSlidingDir(false);
              sliding.current = false;
            }, 500);                    
          } else {
            const current = newChildren[0];             
            newChildren = (newChildren || []).slice(1);
            newChildren.push(current);            
            setTimeout(() => {  
              setChildren(newChildren);     
              setSlidingDir(false);
              sliding.current = false;
            }, 500);                    
          }                              
        }
      }      
    }
  }

  useEffect(() => {       
    const newChilds = (items || []).map((i: any, index) => <div className={styles.carouselItem} key={index}>{i}</div>);
    setChildren(newChilds);          
  },[items])

  const handleResize = () => {    
    if (scrollRef.current || carouselRef.current) {
      const carousel: any = carouselRef.current;
      const container: any = scrollRef.current;
      setHideToggles(carousel.offsetWidth >= container.scrollWidth);      
    }    
  };

  useEffect(() => {
    handleResize();  
  },[width]);

  return (
    <div 
      ref={carouselRef} 
      className={[styles.carousel,className].filter((i)=>i).join(' ')}
    >
      {children &&
        <>
          {['topcenter','topspaced'].includes(toggleStyle) &&
            <div className={[styles.toggles,toggleStyle || 'bottomspaced',hideToggles?styles.hideToggles:''].filter((i)=>i).join(' ')}>
              <span className={styles.togglePrev} onClick={() => slider('prev')}><Arrow height="14px" /></span>
              <span className={styles.toggleNext} onClick={() => slider('next')}><Arrow height="14px" /></span>
            </div>          
          }
          <div 
            ref={scrollRef}         
            className={[styles.carouselInner,slidingDir?((slidingDir==='prev')?styles.carouselMoveRight:styles.carouselMoveLeft):''].filter((i)=>i).join(' ')}
            onTouchStart={onTouchStart} 
            onTouchMove={onTouchMove} 
            onTouchEnd={onTouchEnd}
            data-columns={columns || 3}
          >
            {children}
          </div>
          {['centercenter','bottomcenter','bottomspaced'].includes(toggleStyle) &&
            <div className={[styles.toggles,toggleStyle || 'bottomspaced',hideToggles?styles.hideToggles:''].filter((i)=>i).join(' ')}>
              <span className={styles.togglePrev} onClick={() => slider('prev')}><Arrow height="14px" /></span>
              <span className={styles.toggleNext} onClick={() => slider('next')}><Arrow height="14px" /></span>
            </div>          
          }
        </>
      }
    </div>
  );
}