import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

const Popup = ({ isOpen, onClose, children }: { isOpen: boolean, onClose: () => void, children: React.ReactNode}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const popupRoot = document.getElementById('popup-root');
  

  return popupRoot
    ? ReactDOM.createPortal(
        <div className={styles["popup-overlay"]} onClick={onClose}>
          <div className={styles["popup-content"]} onClick={(e: any) => e.stopPropagation()}>
            <div className={styles["popup-header"]}>
              <button className={styles["popup-close"]} onClick={onClose}>
                &times;
              </button>
            </div>
            <div className={styles["popup-body"]} onClick={(e: any) => e.stopPropagation()}>
              {children}
            </div>
          </div>
        </div>,
        popupRoot
      )
    : null;
};

export default Popup;