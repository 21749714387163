import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ErrorProvider } from './context/Error';
import { AuthProvider } from './context/Auth';

const rootElement = document.getElementById('root');

if (rootElement) {
  // Create a root
  const root = ReactDOM.createRoot(rootElement);

  // Initial render
  root.render(
    <React.StrictMode>
      <ErrorProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ErrorProvider>
    </React.StrictMode>
  );
} else {
  console.error('Root element not found');
}
