import React, {useEffect, useRef, useState } from "react";
import styles from './styles.module.scss';
import useWindowDimensions from "hooks/useWindowDimensions";

const splitArray = (arr: any[]) => {
  const middleIndex = Math.ceil(arr.length / 2); // Find the middle index
  const firstHalf = arr.slice(0, middleIndex);  // Get the first half
  const secondHalf = arr.slice(middleIndex);    // Get the second half
  return [firstHalf, secondHalf];
}


const DesktopNav = ({
  items 
} : { 
  items: any[]
}) => {

  const { width } = useWindowDimensions();

  const navbarRef = useRef<any>(null);
  const navWrapRef = useRef<any>(null);
  const emblemRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const [firstHalf, secondHalf] = splitArray(items);

  useEffect(() => {
    if(navWrapRef.current){
      navWrapRef.current.style.height = navbarRef?.current?.offsetHeight+"px"
    }
  },[width, navbarRef?.current]);


  useEffect(() => {
    const centerEmblem = () => {
      if (emblemRef.current && containerRef.current) {
        const children = Array.from(emblemRef.current.parentElement?.children || []);
        const emblemIndex = children.indexOf(emblemRef.current);
        const emblemWidth = emblemRef.current.offsetWidth
        const beforeEmblemWidth: any = children.slice(0, emblemIndex).reduce((acc: number, child) => {
          if (child instanceof HTMLElement) {
            return acc + child.offsetWidth;
          }
          return acc;
        }, 0);
        containerRef.current.style.marginLeft = `${((beforeEmblemWidth - emblemWidth - 30))}px`;
      }
    };
    centerEmblem()
  }, [width, navbarRef?.current]);

  return (
    <div className={styles.navWrapper} ref={navWrapRef}>
      <div className={styles.navbar} ref={navbarRef}>            
        <div className={styles.navBarWrap} ref={containerRef}>                                    
          <ul className={styles.navbarLinks}>
            {firstHalf.map((item: any, index:number) => <li key={index} className={styles.navlink}>{item}</li>)}
            <li className={styles.navlink} ref={emblemRef}><span className={styles.emblem}>J&D</span></li>
            {secondHalf.map((item: any, index:number) => <li key={index} className={styles.navlink}>{item}</li>)}
          </ul>
        </div>
      </div>
    </div>
  )    
}
export default DesktopNav;