import React, { MouseEvent, ReactNode } from "react";
import styles from './button.module.scss';

export default function Button({
  interaction = "other",
  url,
  newTab,
  children,
  type = "button",
  scheme = "primary",
  size,
  disabled,
  className,
  onClick  
}: {
  interaction?: string;
  url?: string | {[key:string]: any};
  newTab?: boolean
  children: ReactNode;
  type?: "button" | "submit" | "reset" | undefined;
  scheme?: "plain" | "ltplain" | "primary" | "secondary" | "danger" | "success" | undefined;
  size?: "lg" | "sm" | string;
  disabled?: boolean;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}) {

  let checkedUrl: any = url;
  if(!checkedUrl || typeof checkedUrl !== 'object'){
    checkedUrl = (url || "").toString().startsWith('http') || (url || "").toString().startsWith('#') ? (url || "") : "/" + (url || "").toString().replace(/^\/+/, '').replace(/\/\//g, "/");    
  }

  const scrollCheck = (e: any) => {    
    if(typeof checkedUrl !== 'object' && checkedUrl && checkedUrl?.startsWith('#')){      
      e.preventDefault();
      const elem = document.body.querySelector(checkedUrl);
      elem?.scrollIntoView({behavior : "smooth"});
    }
  }

  return (
    <React.Fragment>     
      {interaction === 'link' && (
        <a href={checkedUrl} style={{ textDecoration: 'none' }} target={newTab?"_blank":""} rel="noreferrer" onClick={disabled ? () => false : scrollCheck} className={styles.link}>
          <button type="button" className={[styles.btn, scheme?styles[`btn-${scheme}`]:styles['btn-primary'],size?styles[`btn-${size}`]:null,className].filter((i)=>i).join(' ')} disabled={disabled}>
            {children}
          </button>
        </a>
      )}
      {!['link'].includes(interaction) && (
        <button type={type} className={[styles.btn, scheme?styles[`btn-${scheme}`]:styles['btn-primary'],size?styles[`btn-${size}`]:null,className].filter((i)=>i).join(' ')} disabled={disabled} onClick={disabled ? () => false : onClick}>
        {children}
      </button>
      )}
    </React.Fragment>
  );
}