import React from "react";
import styles from './styles.module.scss';


export function GridContainer({
  children,
  columns = 10,
  className,  
}: {
  columns?: number;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={[styles.gridContainer,className || false].filter(Boolean).join(' ')} data-columns={columns}>
      {children}
    </div>
  )
}

export function GridItem({
  children,  
  start,
  startLg, 
  end,
  endLg,   
}: {
  children?: React.ReactNode;
  start?: number;
  startLg?: number;
  end?: number;
  endLg?: number;  
}) {

  const attributes = {
    'data-start' : start || undefined,
    'data-lg-start' : startLg || undefined,
    'data-end' : end || undefined,
    'data-lg-end' : endLg || undefined,
  }

  return (
    <div 
      className={styles.gridItem} 
      {...attributes}
    >
      {children}
    </div>
  )
}