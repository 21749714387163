const fetchApi = async (
  path: string,
  urlParamsObject: {[key: string]: any} = {},
  options: {[key: string]: any} = {},
  headers: {[key: string]: any} = {},
) => {
  try {
    // Merge default and user options
    const mergedOptions: {[key: string]: any} = {      
      headers: {
        "Content-Type": "application/json", 
        ...headers
      },
      ...options,
    };    

    // Build request URL with query parameters
    let requestUrl = (process.env.REACT_APP_BACKEND || "").toString() + path;
    const queryString = Object.keys(urlParamsObject).map(key => `${key}=${encodeURIComponent(urlParamsObject[key])}`).join('&');
    if (queryString) {
      requestUrl += `?${queryString}`;
    }

    // Trigger API call
    const response = await fetch(requestUrl, mergedOptions)
      .then(response => response.json())
      .catch(error => {
        console.error(error);
        return false;
      });

    return response;
    
  } catch (error) {
    console.error(error);
    return false;
  }
}

export default fetchApi;
