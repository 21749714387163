import React, { createContext, useState } from 'react';


const AuthContext = createContext<any | undefined>(undefined);

export const AuthProvider = ({ children }: any) => {
	const [user, setUser] = useState<boolean | {[key: string]: any}>(false);

	return (
		<AuthContext.Provider value={{ user, setUser }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;