import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Arrow from "../svg/Arrow";

export default function CarouselStack({ 
  items,
  className,
  toggleStyle = 'centercenter',
} : {
  items: any[];
  className?: string;
  toggleStyle?: 'centercenter' | 'bottomcenter' | 'bottomspaced' | 'topcenter' | 'topspaced';
}) {  

  const carouselRef = useRef(null);
  const scrollRef = useRef(null);
  const touchStart = useRef<any>(null);
  const touchEnd = useRef<any>(null);
  const [children, setChildren] = useState<any[]>([]);
  const [touchTapStart, setTouchTapStart] = useState({ x: 0, y: 0, time: 0 });

  const onTouchStart = (e: any) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;

    setTouchTapStart({
      x: e.touches?.[0]?.clientX,
      y: e.touches?.[0]?.clientY,
      time: new Date().getTime(),
    })
  }
  
  const onTouchEnd = (e: any) => {
    const touchTapEnd = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches?.[0]?.clientY,
      time: new Date().getTime(),
    };

    const distanceX = Math.abs(touchTapEnd.x - touchTapStart.x);
    const distanceY = Math.abs(touchTapEnd.y - touchTapStart.y);
    const timeElapsed = touchTapEnd.time - touchTapStart.time;

    const maxDistance = 50;  // Maximum distance to consider as a tap (in pixels)
    const maxTime = 300;     // Maximum time for a tap (in milliseconds)

    if (distanceX < maxDistance && distanceY < maxDistance && timeElapsed < maxTime) {
      slider('next')
      return;
    } else {
      if (!touchStart.current || !touchEnd.current) return
      const distance = touchStart.current - touchEnd.current
      const isLeftSwipe = distance > 100
      const isRightSwipe = distance < -100
      if (isLeftSwipe || isRightSwipe){
        slider(isRightSwipe ? 'prev' : 'next');
        return;
      }
    }
  }

  const onTouchMove = (e: any) => touchEnd.current = e.targetTouches[0].clientX;

  const slider = (dir: string) => {
    let newChildren = [...children];
    if(dir === 'prev'){                       
      const current = newChildren[newChildren.length - 1];
      const sliced = (newChildren || []).slice(0,-1);
      newChildren = [current, ...sliced];                        
      setChildren(newChildren);                    
    } else {
      const current = newChildren[0];             
      newChildren = (newChildren || []).slice(1);
      newChildren.push(current); 
      setChildren(newChildren);                    
    }
  }

  useEffect(() => {       
    const newChilds = (items || []).map((i: any, index) => <div className={styles.carouselItem} key={index}>{i}</div>);
    setChildren(newChilds);          
  },[items])

  return (
    <div 
      ref={carouselRef} 
      className={[styles.carousel,className].filter((i)=>i).join(' ')}
    >
      {children &&
        <>
          {['topcenter','topspaced'].includes(toggleStyle) &&
            <div className={[styles.toggles,toggleStyle || 'bottomspaced'].filter((i)=>i).join(' ')}>
              <span className={styles.togglePrev} onClick={() => slider('prev')}><Arrow height="14px" /></span>
              <span className={styles.toggleNext} onClick={() => slider('next')}><Arrow height="14px" /></span>
            </div>          
          }
          <div 
            ref={scrollRef}         
            className={[styles.carouselInner].filter((i)=>i).join(' ')}
            onTouchStart={onTouchStart} 
            onTouchMove={onTouchMove} 
            onTouchEnd={onTouchEnd}
          >
            {children}
          </div>
          {['centercenter','bottomcenter','bottomspaced'].includes(toggleStyle) &&
            <div className={[styles.toggles,toggleStyle || 'bottomspaced'].filter((i)=>i).join(' ')}>
              <span className={styles.togglePrev} onClick={() => slider('prev')}><Arrow height="14px" /></span>
              <span className={styles.toggleNext} onClick={() => slider('next')}><Arrow height="14px" /></span>
            </div>          
          }
        </>
      }
    </div>
  );
}