import React from 'react';
import styles from './styles.module.scss';


const LoadingWheel = ({ className, light, width} : {className?: string; light?: boolean; width?: string }) => {

  return (
    <div 
      className={[styles.loadingWheel,className,light?styles.light:''].filter((i)=>i).join(' ')} 
      style={{width : width || undefined,height : width || undefined}}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingWheel;